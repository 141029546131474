@font-face {
  font-family: "Shapiro";
  font-weight: normal;
  font-style: normal;

  src: url("/fonts/Shapiro-Text-Regular.woff2") format("woff2"),
    url("/fonts/Shapiro-Text-Regular.woff") format("woff");
}

@font-face {
  font-family: "Shapiro";
  font-weight: bold;
  font-style: normal;

  src: url("/fonts/Shapiro-Text-LightHeavy.woff2") format("woff2"),
    url("/fonts/Shapiro-Text-LightHeavy.woff") format("woff");
}

@font-face {
  font-family: "Shapiro Extended";
  font-weight: normal;
  font-style: normal;

  src: url("/fonts/Shapiro-Extended-LightHeavy.woff2") format("woff2"),
    url("/fonts/Shapiro-Extended-LightHeavy.woff") format("woff");
}

@font-face {
  font-family: "Shapiro Extended";
  font-weight: bold;
  font-style: normal;

  src: url("/fonts/Shapiro-Extended-SuperHeavy.woff2") format("woff2"),
    url("/fonts/Shapiro-Extended-SuperHeavy.woff") format("woff");
}

@font-face {
  font-family: "Shapiro Extended Air";

  src: url("/fonts/Shapiro-Extended-Air.woff2") format("woff2"),
    url("/fonts/Shapiro-Extended-Air.woff") format("woff");
}

* {
  box-sizing: border-box;
}

a {
  font-weight: bold;
  text-decoration: underline;
  color: black;
}

html,
button,
input,
select,
textarea {
  font-family: "Shapiro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 680px) {
  html,
  button,
  input,
  select,
  textarea {
    font-size: 22px;
  }
}
